@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&Abril+Fatface&family=GFS+Didot&family=Montserrat:wght@400;500&family=Open+Sans:wght@400;500&display=swap');
@import '~bootstrap/dist/css/bootstrap.css';
:root {
    --font-a : 'Bebas Neue', sans-serif;
    --font-b : 'Open Sans', sans-serif;
    --font-c : 'Montserrat', sans-serif;
    --font-d : 'GFS Didot', serif;
    --bg     : #FFE4F1;
    --bg-copy: #C84091;
    --follow : #773f4c;
    --bg-title  : #B3ADA9;
    --he : 120px;
}
.btn-secondary {
    --bs-btn-disabled-border-color:transparent;
    --bs-btn-disabled-bg: #c84091;
}
* {
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    height: 100%;
    overflow-x: hidden;
}

body {
    overflow-x: hidden;
    color:#492F3A;
}

body,
button {
    font-family: var(--font-b);
}
::-webkit-scrollbar {
    background-color: var(--bg);
    width: 0.3rem;
}

::-webkit-scrollbar-thumb {
    background-color: var(--bg-title);
}
.f-1 {
    font-family: var(--font-a);
}
.cl-1 {
    color:var(--bg-title);
}
.cl-2 {
    color:var(--follow);
}
.bg-1 {
    background-color: var(--bg);
}
.sub-title {
    font-family: var(--font-a);
    font-size: calc(2rem + 10px);
    color: var(--bg-title);
}
.max-img {
    max-width: 100%}
.socmed-banner {
    position: absolute;
    bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap}
    .socmed-banner h3 {
        font-size: calc(1rem - 2px);
        font-weight: 700;
        margin:0}
.sosmed a {
    color:#fff;
    display: inline-flex;
    width: 30px;
    height: 30px;
    background: var(--bg-title);
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    margin-right: 5px;
    font-size: .87rem}
.button {
    padding: .5rem 2rem;
    transition: ease all .3s;
    color:#fff;
    text-align: center;
    text-decoration: none;
    letter-spacing: .05rem;
    border-radius: 30px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#293990 0 -3px 0 inset;
    background-color: #293990}
    .button:hover {
        box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #293990 0 -3px 0 inset;
        transform: translateY(-2px)}
    .button:focus {
        box-shadow: #293990 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #293990 0 -3px 0 inset}
    .button:active {
        box-shadow: #293990 0 3px 7px inset;
        transform: translateY(2px)}
.our__treatment {
    list-style: none;
    padding: 0;
    margin:0;}
    .our__treatment li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 2rem;
    }
    .our__treatment .our__treatment__image {
        position: relative;
        width: 100%; }
        .our__treatment .our__treatment__image img {
            box-shadow:-16px -16px 0px 0px #F8F3F2;
            max-width: 100%;
            margin: 2rem 0rem 0rem 0.5rem;
            width: 100%;
        }
    .our__treatment .our__treatment__content {
        width: 90%;
        background-color: rgb(242 235 233 / 64%);
        margin-top: -50px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        margin-right: 0;
        margin-left: auto;
        padding: 1rem; }
        .our__treatment h3 {
            font-size: 1.4rem;
            font-weight: 600;
        }
        .our__treatment p {
            margin-top: 1.2rem;
        }
    @media screen and (min-width: 560px) {
        .our__treatment .our__treatment__image { width: 40%; }
        .our__treatment .our__treatment__image img {
            box-shadow: -35px -35px 0px 0px #F8F3F2;
            margin: 2rem 0rem 2rem -2rem;
        }
        .our__treatment .our__treatment__content {
            width: 58%;
            margin-top:0;
            margin-left: 0;
            padding: 0;
            background-color: transparent; }
            .our__treatment h3 {
                font-size: 2rem;
                font-weight: 600;
            }
    }
    @media screen and (min-width: 1080px) {
        .our__treatment .our__treatment__image {
            width: 455px;
            max-width: 40%;
        }
    }
.z-1 {
    z-index: 1;
}
.relative-index {
    position: relative;
    z-index: 1;
}
.modal-body {
    padding:0;
}
.modal-treatment {
    position: relative;z-index: 1;}
    .modal-treatment::before, .modal-treatment::after {
        position: absolute;
        content: '';
        background: url('assets/img/decor-2.svg') no-repeat 0px 0px;
        z-index:0}
        .modal-treatment::before {
            top: 55%;
            width: 300px;
            height: 255px;
            left: 0%;}
        .modal-treatment::after {
            top: 35%;
            width: 300px;
            height: 255px;
            right: -48px;
            transform: rotate(130deg) scale(.7)}
    .modal-treatment > .container-fluid::after{
        position: absolute;
        content: '';
        background: url('assets/img/decor.svg') no-repeat 0px 0px;
        z-index:0;
        bottom: 5%;
        width: 300px;
        height: 255px;
        left: 0%;
    }

.btn-modal-treatment {
    display:block;
    padding: .5rem .8rem;
    transition: ease all .3s;
    color:#fff;
    text-align: center;
    text-decoration: none;
    letter-spacing: .05rem;
    border-radius: 30px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#293990 0 -3px 0 inset;
    background-color: #293990}
    .button:hover {
        box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #293990 0 -3px 0 inset;
        transform: translateY(-2px)}
    .button:focus {
        box-shadow: #293990 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #293990 0 -3px 0 inset}
    .button:active {
        box-shadow: #293990 0 3px 7px inset;
        transform: translateY(2px)}
@media screen and (min-width: 280px) and (max-width: 1080px) {
    body::-webkit-scrollbar {
        display: none;
    }
}